.menu-bar {
    height: 50px;
    padding : 2vh 5vh 2vh 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 0;
    z-index: 20;
    position: sticky;
    background-color: #F9F6EE;
    border-bottom: 1px solid #444444;
    width: calc(100% - 10vh);
}

.menu-bar > .menu-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 13vh;
}

.menu-navigation a {
    display: block;
    width: 200px;
    border-bottom: 1px dashed #444444;
    text-decoration: none;
    color: #444444;
}

.menu-navigation a:hover {
    font-weight: bold;
}

.menu-navigation a > p {
    font-size: 18px;
    margin: 18px 0 1vh 0;
}

.menu-buttonStyle {
    width: 80px;
    border: 0;
    transition: .2s, -webkit-mask-position .2s .2s ; 
    background-color: transparent;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #444444 !important;
    gap : 10px;
    border-radius: .375rem;
}

.menu-buttonStyle > img {
    transition: transform 0.5s ease;
    margin: 18px 0 1vh 0;
}

.menu-buttonStyle:hover > img {
    transform: rotate(405deg);
}

.menu-buttonStyle > p {
    margin: 18px 0 1vh 0;
}

@media (max-width: 760px) {
    .menu-bar {
        padding : 1vh 3vh 1vh 3vh !important;
        width: calc(100% - 6vh);
    }
    .menu-bar > .menu-navigation {
        gap: 2vh !important;
    }
    .menu-navigation a {
        width: fit-content !important;
    }

    .menu-bar > a > img {
        width: 50px;
        height: 50px;
    }

    .menu-buttonStyle {
        display: none !important;
    }
}