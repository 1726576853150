.home {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 10vh;
    padding-right: 40px;
    padding-left: 40px;
    min-height: calc(100vh - 20vh - 4vh - 52px);
}

.home-mainPhrase {
    width: 90%;
    text-align: center;
}

.home-mainPhrase h1 {
    margin-top: 0;
    font-size: 3.5rem;
    font-weight: inherit;
    text-transform: capitalize;
}

@media (max-width: 760px) {
    .home-mainPhrase h1 {
        font-size: 2.5rem !important;
    }
    .home {
        padding-top: 0 !important;
    }
}

.home-mainPhrase > h3 {
    font-weight: inherit;
    font-size: 1.25rem;
}

.home-listCard {
    display: grid;
    grid-template-columns: repeat(3, 0.3fr); /* 3 colonnes de largeur égale */
    grid-gap: 5rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 3rem;
    padding: 2rem;
}

@media (max-width: 1105px) {
    .home-listCard {
        grid-template-columns: repeat(2, 0.5fr 0.5fr); /* Adjusted to create sub-columns */
    }
    .home-card:first-child {
        grid-column: 1 / 3;
        grid-row: 1;
    }
    .home-card:nth-child(2) {
        grid-column: 3 / 6;
        grid-row: 1;
    }
    .home-card:nth-child(3) {
        grid-column: 2 / 4;
        grid-row: 2;
    }
}

@media (max-width: 760px) {
    .home-listCard {
        grid-template-columns: repeat(1, 1fr) !important; /* Adjusted to create sub-columns */
    }
    .home-card {
        grid-column: 1 !important;
    }
    .home-card:first-child {
        grid-row: 1;
    }
    .home-card:nth-child(2) {
        grid-row: 2;
    }
    .home-card:nth-child(3) {
        grid-row: 3;
    }
}

.home-card {
    border: 1px solid #444444;
    border-radius: .375rem;
    background-color: #F9F6EE;
    box-shadow: -1px 1px 6px 1px #4444444a;
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    gap: 0.7rem;
    height: min-content;
}

.home-card > h3 {
    margin: 0;
    font-size: 1.2rem;
    text-transform: capitalize;
    border-bottom: 1px solid #444444;
    padding-bottom: 0.5rem;
}

.home-card > p {
    margin: 0;
    font-size: 0.875rem;
}

.home-card > button {
    border: 1px solid #444444;
    background-color: transparent;
    border-radius: .375rem;
    width: fit-content;
    margin-top: auto;
    padding-top: .375rem;
    padding-bottom: .375rem;
    padding-left: .625rem;
    padding-right: .625rem;
    align-self: flex-end;
}


.home-card > button:hover {
    background-color: #444444;
    cursor: pointer;
    border: 1px solid #444444;
    color: #F9F6EE;
}

.home-sectionHome {
    border-top: 1px solid #444444;
    width: 100%;
    min-height: calc(100vh - 4vh - 52px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap : 10%;
}

@media (max-width: 760px) {
    .home-sectionHome {
        flex-direction: column !important;
    }

    .home-sectionHome > img {
        height: calc(40vh - 4vh - 52px) !important;
        margin: .5rem;
    }

    .home-sectionHome > div {
        width: 80% !important;
    }
}
.home-sectionHome > div {
    border-radius: .375rem;
    width: 50%;
    text-align: justify;
    padding: 0.5rem;
}

.home-sectionHome > img {
    height: calc(80vh - 4vh - 52px);
    width: auto;
    box-shadow: -1px 1px 6px 1px #4444444a;
    border-radius: .375rem;
}

.home-sectionTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
