body {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F6EE;
  color: #444444 !important;
  background-image: url('/public/background.png'); /* Remplacez 'your-image-url.jpg' par le chemin de votre image */
  background-repeat: repeat;
  background-position: center; /* L'image est centrée */
  min-height: 100vh;
  letter-spacing: .025em;
}

html, body {
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer {
  color: #444444 !important;
  text-align: center;
  bottom: 0;
  position: relative;
  padding: 0.3rem;
  border-top: solid 1px #444444;
}

.footer-link {
  display: flex;
  flex-direction: column;
}

.footer-link a:hover {
  text-decoration: underline;
}

footer > p {
  margin: 0;
  padding-bottom: 0.2rem;
}

footer, a {
  text-decoration: none;
  color: #444444 !important;
}
footer button {
  color: black !important;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 5%;
}


.footer-container p {
  font-size: 14px;
  margin: 0;
}
