.roadmap-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.roadmap-title > h1 {
    margin-bottom: 0.5rem;
}

.roadmap-sectionHome {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 72rem;
    margin: 0 auto;
    position: relative;
    padding: 2rem 0;
}

/* Create the central timeline */
.roadmap-sectionHome::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 4px;
    background-color: #444444;
}

.roadmap-sectionHome > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.roadmap-sectionHome > div > h2 {
    background-color: #444444;
    color: #F9F6EE;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    z-index: 1;
    margin-bottom: 1rem;
}

.roadmap-sectionHome > div > p {
    width: 35%;
    padding: 1rem;
    background-color: #f9f6ee;
    border: 1px solid #444444;
    border-radius: 4px;
    position: relative;
}

/* Left side content */
.roadmap-sectionHome > div > p:nth-child(even) {
    align-self: flex-start;
    text-align: right;
    margin-left: 20%;
}

/* Right side content */
.roadmap-sectionHome > div > p:nth-child(odd) {
    align-self: flex-end;
    text-align: left;
    margin-right: 20%;
}



/*
.roadmap-sectionPoint {
    display: flex;
    flex-direction: row;
    justify-content : space-around;
}

@media (max-width: 760px) {
    .roadmap-sectionHome {
        flex-direction: column !important;
        border: 0 !important;
    }

    .roadmap-sectionHome > img {
        height: calc(50vh - 4vh - 52px) !important;
        margin: .5rem;
    }

    .roadmap-sectionTexte > div {
        margin-left: 0 !important;
        padding: 0.5rem;
    }

    .rd {
        display: none !important;
    }

    .roadmap-sectionPoint {
        flex-direction: column !important;
    }
}

.roadmap-sectionFirst > div {
    margin-top: 3rem;
}

.roadmap-sectionTexte {
    min-height: calc(50vh - 4vh - 52px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.roadmap-sectionTexte > div {
    width: 100%;
    text-align: justify;
    margin-left: 8rem;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
}

.roadmap-sectionTexte p {
    font-size: 1.1rem;
}

.roadmap-sectionTexte li {
    font-size: 1.1rem;
}

.roadmap-sectionHome > img {
    height: calc(80vh - 4vh - 52px);
    width: auto;
    box-shadow: -1px 1px 6px 1px #4444444a;
    border-radius: .375rem;
}

.rd {
    position: absolute;
    left: -0.65rem; 
    height: 1.25rem; 
    width: 1.25rem; 
    border-radius: 9999px; 
    border-width: 1px;
    border-color: #444444;
    background-color: #F9F6EE; 
    border-color: #F9F6EE;
    background-color: #444444; 
}

.tag {
    display: flex;
    flex-direction: row;
    gap: 2%;
}

.tag > p {
    border: 1px solid #444444;
    border-radius: .375rem;
    background-color: #444444;
    color: #F9F6EE;
    margin-top: 0;
    padding: 0.2rem;
    font-size: 1rem;
    width: fit-content;
} */